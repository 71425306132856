import React, { useRef, useEffect } from "react";
import { TweenMax, Power1 } from 'gsap/TweenMax'
import { withTheme } from 'emotion-theming'
import styled from "@emotion/styled";

const CapitolAnimation = ({theme: {primary: {color}, rules: {color: rulesColor}}}) => {
  const scalesRef = useRef();
  const gavelRef = useRef();
  const square1 = useRef();
  const square2 = useRef();
  const square3 = useRef();
  const square4 = useRef();
  const square5 = useRef();
  const square6 = useRef();

  const animate = () => {
    TweenMax.to(scalesRef.current, 3, {y: 15, ease: Power1.easeInOut}).yoyo(true).repeat(-1)
    TweenMax.to(gavelRef.current, 2.5, {y: 15, ease: Power1.easeInOut}).yoyo(true).repeat(-1)
    TweenMax.to(square1.current, 3.7, {y: 15, ease: Power1.easeInOut}).yoyo(true).repeat(-1)
    TweenMax.to(square2.current, 2, {y: 15, ease: Power1.easeInOut}).yoyo(true).repeat(-1)
    TweenMax.to(square3.current, 4, {y: 15, ease: Power1.easeInOut}).yoyo(true).repeat(-1)
    TweenMax.to(square4.current, 1.5, {y: 15, ease: Power1.easeInOut}).yoyo(true).repeat(-1)
    TweenMax.to(square5.current, 3.5, {y: 15, ease: Power1.easeInOut}).yoyo(true).repeat(-1)
    TweenMax.to(square6.current, 2.1, {y: 15, ease: Power1.easeInOut}).yoyo(true).repeat(-1)
  }

  useEffect(() => {
    animate()
  }, [])

  return (
  <SVGWrapper viewBox="0 0 454.62 370.22">
    <title>{'Scales of Justice & a Gavel'}</title>
    <g data-name="Layer 2">
      <g data-name="Layer 1">
        <path
          ref={square1}
          d="M298.12 143.45l-29.24-14.63a5.85 5.85 0 00-5.19 0l-29.27 14.63a1.09 1.09 0 000 2L263.69 160a5.78 5.78 0 005.19 0l29.24-14.62a1.09 1.09 0 000-1.93zM108.45 317.89l-34.06-17a6.76 6.76 0 00-6 0l-34.09 17a1.28 1.28 0 000 2.29l34.09 17a6.76 6.76 0 006 0l34.06-17a1.27 1.27 0 000-2.29z"
          fill="#2c58c4"
        />
        <path
          ref={square2}
          d="M230.73 340.3l-24.88-12.44a5 5 0 00-4.42 0l-24.9 12.45a.93.93 0 000 1.67l24.9 12.44a5 5 0 004.41 0L230.73 342a.93.93 0 000-1.7zM275 260.37l-128.87-64.43a25.58 25.58 0 00-22.86 0L16.21 249.72a4.83 4.83 0 000 8.64l129 64.41a25.56 25.56 0 0022.84 0L275 269a4.84 4.84 0 000-8.63z"
          fill="#fff"
          stroke="#2c58c4"
          strokeMiterlimit={10}
          strokeWidth={2}
        />
        <path
          ref={square3}
          d="M434.5 230l-128.4-64.2a25.48 25.48 0 00-22.77 0l-90.78 46a4.82 4.82 0 000 8.62l128.5 64.18a25.45 25.45 0 0022.77 0l90.68-46a4.82 4.82 0 000-8.6z"
          fill="#fff"
          stroke="#2c58c4"
          strokeMiterlimit={10}
          strokeWidth={2}
        />
        <path
          ref={square4}
          d="M296.77 206.93l-103-51.52a20.45 20.45 0 00-18.28 0L92 197.17a3.86 3.86 0 000 6.91l103.13 51.51a20.43 20.43 0 0018.27 0l83.38-41.75a3.86 3.86 0 00-.01-6.91z"
          fill="#fff"
          stroke="#2c58c4"
          strokeMiterlimit={10}
          strokeWidth={2}
        />
        <path
          ref={square5}
          d="M389.67 279.86l-93.51-46.75a18.53 18.53 0 00-16.58 0L186 279.88a3.51 3.51 0 000 6.28l93.57 46.73a18.53 18.53 0 0016.58 0l93.51-46.75a3.52 3.52 0 00.01-6.28z"
          fill="#2c58c4"
          stroke="#fff"
          strokeMiterlimit={10}
          strokeWidth={2}
        />
        <path
          ref={square6}
          d="M206.92 183.35l-72.81-36a22.2 22.2 0 00-19.84 0l-111.95 56a4.2 4.2 0 000 7.51l72.9 36a22.21 22.21 0 0019.83 0l111.87-55.94a4.19 4.19 0 000-7.57z"
          fill="#2c58c4"
        />
        <g ref={scalesRef} transform="translate(30 0)">
          <path
            d="M262.41 238.16v.56c-.61 11.76-20.16 21.2-44.19 21.2s-43.58-9.44-44.19-21.2v-.56-.57c.53-13.45 20.11-24.27 44.19-24.27s43.66 10.82 44.19 24.27v.57z"
            fill="#767776"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
          />
          <path
            d="M210.58 170.77a68.51 68.51 0 01.44 19.7c-.57 4.86-2.2 10-2.42 14.83a53.73 53.73 0 00.53 10.82l-.14 2.6 6.49 5.59 5.9-.86 5.29-3.87.34-3.46a54.64 54.64 0 00.53-10.82c-.22-4.81-1.86-10-2.43-14.83a68.51 68.51 0 01.44-19.7z"
            fill="#767776"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
          />
          <path
            d="M228 220.11a1.09 1.09 0 010 .18c0 3-4.45 5.45-9.93 5.45s-9.94-2.44-9.94-5.45v-2.92a3.46 3.46 0 01.83-2.19 3.14 3.14 0 001.04 1.92l.29.27c1.61 1.33 4.48 2.21 7.76 2.21s6.14-.88 7.75-2.21l.29-.27a3.19 3.19 0 001.06-1.91 3.49 3.49 0 01.83 2.18 1.09 1.09 0 010 .18zM227.17 162.58s-2.76 0 0-30.57l-9.1 2-9-2c2.74 30.57-.09 30.57-.09 30.57l.79 4.7 8.06 1.69 6.7-.46 2.43-2.75z"
            fill="#767776"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
          />
          <path
            d="M228 167.5a1.21 1.21 0 010 .19c0 3-4.45 5.44-9.93 5.44s-9.94-2.44-9.94-5.44v-2.93a3.43 3.43 0 01.83-2.18 3.19 3.19 0 001.04 1.92l.29.26c1.61 1.33 4.48 2.22 7.76 2.22s6.14-.89 7.75-2.22l.29-.26a3.24 3.24 0 001.06-1.92 3.51 3.51 0 01.83 2.18 1.21 1.21 0 010 .19zM225.72 112c-.58 10.31 1.31 15.41 1.31 15.41l-.41 3.48-7 3.32-7-.62-3.26-2.87-.3-3.31s1.9-5.1 1.33-15.41l7.64 2.29z"
            fill="#767776"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
          />
          <path
            d="M227.85 131.07a1.09 1.09 0 010 .18c0 3-4.39 5.37-9.79 5.37s-9.8-2.41-9.8-5.37v-1.72a3.4 3.4 0 01.82-2.15 3.19 3.19 0 001 1.89l.29.26c1.59 1.31 4.42 2.18 7.65 2.18s6.05-.87 7.63-2.18l.29-.26a3.06 3.06 0 001-1.89 3.38 3.38 0 01.83 2.15 1.09 1.09 0 010 .18zM208.28 107.42s2.83-14.86 3.17-67.42l6.62-2.69 6.62 2.69c.34 52.56 3.17 67.42 3.17 67.42l-1 4.55-6.3 2.18h-5L209.5 112l-1.5-2.21z"
            fill="#767776"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
          />
          <path
            d="M228.73 110.47a1.34 1.34 0 010 .2c0 3.23-4.78 5.85-10.67 5.85s-10.68-2.62-10.68-5.85v-.91a3.7 3.7 0 01.89-2.34 3.47 3.47 0 001.14 2.06 2.8 2.8 0 00.31.28c1.73 1.43 4.82 2.38 8.34 2.38s6.6-1 8.32-2.38a2.92 2.92 0 00.32-.28 3.44 3.44 0 001.14-2.06 3.76 3.76 0 01.89 2.34 1.27 1.27 0 010 .2z"
            fill="#767776"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
          />
          <path
            d="M285.35 75.68a4.17 4.17 0 01-.85-.09c-3.64-.75-11-3.71-18.22-7.35a76.89 76.89 0 01-8.67-5.24 84.68 84.68 0 00-9.54-5.71 127.29 127.29 0 00-12-5.1 105.25 105.25 0 01-9.77-4.09L207 38.47c-3-1.51-6.12-3.47-9.41-5.54-3.7-2.33-7.52-4.73-11.64-6.8a74.88 74.88 0 00-9.95-4.08 68.64 68.64 0 01-9.06-3.7c-7.24-3.64-14.23-7.87-17.39-10.53a4.72 4.72 0 01-1.73-5A2.82 2.82 0 01150.65 1a9 9 0 015 2.13c7.18 5.3 12.55 8.6 16.92 10.37 1.1.45 2.39.87 3.89 1.35A71.51 71.51 0 01197.29 25a53.28 53.28 0 0014.56 7.49 61.19 61.19 0 017.18 3 70.12 70.12 0 016.89 4.1 52.9 52.9 0 0014.62 7.18c9.57 3 16 7.49 20.71 10.75 1.38 1 2.58 1.79 3.63 2.43 4.18 2.52 9.82 4.75 17.73 7 4.8 1.38 6.18 4.15 5.91 6.1a3.11 3.11 0 01-3.17 2.66z"
            fill="#767776"
          />
          <path
            d="M150.65 2c1 0 2.5.54 4.38 1.93 6.47 4.78 12.26 8.52 17.14 10.5s13.28 3.43 24.55 11.37c10.21 7.2 14.46 6.81 21.86 10.53s10.54 7.82 21.66 11.33c12.27 3.89 19.45 10.26 24.13 13.08s10.82 5.09 18 7.14c6.7 1.93 6.06 6.8 3 6.8a3.79 3.79 0 01-.65-.07c-3.58-.74-10.93-3.72-18-7.26s-10.33-7-18.21-10.92-15.56-6-21.82-9.2l-9.62-4.83-9.62-4.83c-6.26-3.15-13.16-8.38-21-12.34s-11.94-4.23-19-7.77-14.08-7.78-17.19-10.4C148 5.2 148.11 2 150.65 2m0-2a3.78 3.78 0 00-3.73 2.49 5.74 5.74 0 002 6.1c3.21 2.7 10.27 7 17.58 10.66a69.53 69.53 0 009.21 3.75 74.11 74.11 0 019.81 4c4.08 2.05 7.88 4.44 11.56 6.75 3.31 2.09 6.45 4.06 9.49 5.59l9.61 4.83L225.8 49a106.74 106.74 0 009.86 4.14 125.77 125.77 0 0112 5.06 83.07 83.07 0 019.43 5.64 78.15 78.15 0 008.78 5.27c7.33 3.68 14.75 6.67 18.47 7.44a5.35 5.35 0 001.05.11 4.09 4.09 0 004.16-3.52c.33-2.35-1.21-5.64-6.62-7.2-7.87-2.23-13.43-4.44-17.53-6.94-1-.62-2.21-1.44-3.58-2.39-4.75-3.3-11.26-7.81-21-10.88a51.53 51.53 0 01-14.38-7.07 67.44 67.44 0 00-7-4.15 59.51 59.51 0 00-7.29-3 52.12 52.12 0 01-14.32-7.36 72.09 72.09 0 00-21.08-10.25c-1.48-.48-2.76-.89-3.82-1.33-4.29-1.74-9.6-5-16.71-10.25A9.86 9.86 0 00150.65 0z"
            fill="#fff"
          />
          <circle
            cx={218.07}
            cy={38.34}
            r={7.81}
            fill="#767776"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
          />
          <path
            d="M326.19 209.76c-.11 5.14-2.5 9.94-6.56 14-7.67 7.71-21.33 12.84-36.91 12.84s-29.24-5.14-36.92-12.86c-4.06-4.08-6.43-8.87-6.55-14 .15 4 2.53 7.79 6.55 11 7.68 6.11 21.34 10.18 36.92 10.18s29.24-4.06 36.91-10.17c4.03-3.19 6.37-6.96 6.56-10.99z"
            fill="#767776"
            stroke="#767776"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
          />
          <path
            d="M326.2 209.37c0 4.18-2.41 8.09-6.57 11.39-7.67 6.11-21.33 10.17-36.91 10.17s-29.24-4.07-36.92-10.18c-4.16-3.3-6.56-7.2-6.56-11.38 0-11.91 19.47-21.57 43.48-21.57s43.48 9.66 43.48 21.57z"
            fill="#fff"
            stroke="#767776"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
          />
          <path
            d="M322 214.1a11.14 11.14 0 01-2.36 6.66c-7.67 6.11-21.33 10.17-36.91 10.17s-29.24-4.07-36.92-10.18a11.1 11.1 0 01-2.35-6.65c0-10.76 17.58-19.48 39.27-19.48S322 203.34 322 214.1zM248.95 222.95L282.72 71.1M272.9 188.36l9.82-117.26M321.99 200.1l-39.27-129"
            fill="#fff"
            stroke="#767776"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
          />
          <path
            d="M194.12 143.38c-.11 5.14-2.5 9.94-6.56 14-7.67 7.72-21.33 12.85-36.91 12.85s-29.24-5.14-36.92-12.86c-4-4.08-6.43-8.87-6.55-14 .15 4 2.53 7.79 6.55 11 7.68 6.11 21.34 10.18 36.92 10.18s29.24-4.06 36.91-10.17c4.03-3.2 6.44-6.97 6.56-11z"
            fill="#767776"
            stroke="#767776"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
          />
          <path
            d="M194.13 143c0 4.18-2.41 8.09-6.57 11.39-7.67 6.11-21.33 10.17-36.91 10.17s-29.24-4.07-36.92-10.18c-4.15-3.3-6.55-7.2-6.55-11.38 0-11.91 19.46-21.57 43.47-21.57s43.48 9.65 43.48 21.57z"
            fill="#fff"
            stroke="#767776"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
          />
          <path
            d="M189.92 147.72a11.14 11.14 0 01-2.36 6.66c-7.67 6.11-21.33 10.17-36.91 10.17s-29.24-4.07-36.92-10.18a11.16 11.16 0 01-2.35-6.65c0-10.76 17.58-19.48 39.27-19.48s39.27 8.76 39.27 19.48zM114.23 131.21L150.65 4.72M155.66 164.55L150.65 4.72M184.23 129.29L150.65 4.72"
            fill="#fff"
            stroke="#767776"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
          />
          <g strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} ref={gavelRef}>
            <ellipse
              cx={97.02}
              cy={221.59}
              rx={18.87}
              ry={23.47}
              transform="rotate(-48.84 96.999 221.574)"
              fill="#767776"
              stroke="#fff"
            />
            <ellipse
              cx={91.44}
              cy={227.32}
              rx={19.19}
              ry={24.53}
              transform="rotate(-48.84 91.422 227.304)"
              fill="#767776"
              stroke="#fff"
            />
            <path
              d="M105.89 246.08a17 17 0 01-1.26 1.28l-7.29 7.9-4.5-3.44-25.17-19.24-7.29-5.58 9.38-9.91a15.93 15.93 0 011.2-1.57c7-8 20.45-7.6 30.09.83s11.81 21.75 4.84 29.73z"
              fill="#fff"
              stroke="#767776"
            />
            <ellipse
              cx={75.86}
              cy={244.4}
              rx={19.19}
              ry={24.53}
              transform="rotate(-48.84 75.846 244.38)"
              fill="#767776"
              stroke="#fff"
            />
            <ellipse
              cx={69.38}
              cy={251.38}
              rx={17.85}
              ry={22.86}
              transform="rotate(-48.84 69.353 251.366)"
              fill="#767776"
              stroke="#fff"
            />
            <ellipse
              cx={67.95}
              cy={254.18}
              rx={12.74}
              ry={16.31}
              transform="rotate(-48.84 67.942 254.152)"
              fill="#767776"
              stroke="#fff"
            />
            <g fill="#767776" stroke="#fff">
              <path d="M105 236a3.93 3.93 0 015.07 2l1.32 1s1.65-2.33 5.12-1 3.17 3.8 3.17 3.8 12.54 6.38 24 8.94c0 0 1.62-2 4.92-.94s3.26 2.46 3.26 2.46 19.52 8.92 59.33 22.61S266 292.3 266 292.3a3 3 0 013.18-.39c2.08.75 2.09 2.07 2.09 2.07s4.27 1.53 7.75 3.13 6.05 3.39 5.2 6.3l-.24.64c-1.07 2.83-4.21 2.71-7.94 1.89s-8.08-2.16-8.08-2.16-.88 1-3 .37-2.21-2.2-2.21-2.2-14.2-6-54.22-19.07-61-17.69-61-17.69-.81 1.16-4.08 0-3.36-3.72-3.36-3.72c-10.71-4.87-24.56-7.33-24.56-7.33s-1.23 2.14-4.81 1.12-3.5-3.88-3.5-3.88h-1.66a3.9 3.9 0 01-5.26-1.42s-.73-11.17 4.7-13.96zM107.24 251.38s-.52-5.56 4.18-12.4" />
              <path d="M268 303.78s-2.9-.41-.6-6 3.91-3.76 3.91-3.76M262.75 302s-.64-1.6.83-5.76c1.08-3 2.42-3.89 2.42-3.89M140.11 261.47a12.57 12.57 0 01.83-6 11.24 11.24 0 012.79-4.71M147.55 265.19s-3-.6-.34-7.73c2.52-6.67 4.7-5.21 4.7-5.21M115.55 254.14s-4.85 1.65-1.32-8.32c2.71-7.66 5.48-4 5.48-4M105.58 251.38s-1.86-1.68 1-8.47c2.13-5.11 3.54-4.93 3.54-4.93" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </SVGWrapper>
  );
};

export default withTheme(CapitolAnimation);

const SVGWrapper = styled("svg")({
  width: "100%",
  maxWidth: 500
}, ({theme}) => ({
  [theme.media.min.lg]: {
    maxWidth: 600
  }
}));
